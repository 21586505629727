.container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
}

.formContainer {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.signInContainer {
    left: 0;
    width: 50%;
    z-index: 2;
}

.container.rightPanelActive .signInContainer {
    transform: translateX(100%);
}

.signUpContainer {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.container.rightPanelActive .signUpContainer {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
}

@keyframes show {
    0%,
    49.99% {
        opacity: 0;
        z-index: 1;
    }

    50%,
    100% {
        opacity: 1;
        z-index: 5;
    }
}

.overlayContainer {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.container.rightPanelActive .overlayContainer {
    transform: translateX(-100%);
}

.overlay {
    background: #86E900;
    background: -webkit-linear-gradient(to right, #86E900, #86E900);
    background: linear-gradient(to right, #86E900, #86E900);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #ffffff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.container.rightPanelActive .overlay {
    transform: translateX(50%);
}

.overlayPanel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.overlayLeft {
    transform: translateX(-20%);
}

.container.rightPanelActive .overlayLeft {
    transform: translateX(0);
}

.overlayRight {
    right: 0;
    transform: translateX(0);
}

.container.rightPanelActive .overlayRight {
    transform: translateX(20%);
}

button {
    border-radius: 20px;
    border: 1px solid #86E900;
    background-color: #86E900;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
}

button:active {
    transform: scale(0.95);
}

button:focus {
    outline: none;
}

button.ghost {
    background-color: transparent;
    border-color: #ffffff;
}

form {
    /*background-color: #ffffff;*/
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
}

input {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
}

.socialContainer {
    margin: 20px 0;
}

.socialContainer a {
    border: 1px solid #dddddd;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
}
